@import url(https://fonts.googleapis.com/css2?family=Asset&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
:root {
  --color-primary: rgb(0, 157, 224);
}

li:hover {
  color: rgb(0, 157, 224);
  color: var(--color-primary);
}

.black-nav:hover {
  color: rgb(0, 157, 224) !important;
  color: var(--color-primary) !important;
}

.card {
  width: 100%;
  text-align: left;
  padding: 0px 20px;
}

.card-body {
  bottom: 20px;
  font-family: 'Arial Black', Gadget, sans-serif;
}

.name {
  color: rgb(0, 157, 224);
  color: var(--color-primary);
  text-transform: uppercase;
  font-size: 19px;
  font-style: bold;
  font-weight: 700;
}

.name-modal {
  color: rgb(0, 157, 224);
  color: var(--color-primary);
  text-transform: uppercase;
  font-size: 28px;
  font-style: bold;
  font-weight: 700;
}

.name-black {
  color: black;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  font-style: bold;
  cursor: pointer;
}

.album {
  font-style: italic;
  font-size: 14px;
  opacity: 0.6;
}

.coming-soon {
  font-style: bold;
  font-size: 19px;
  text-transform: uppercase;
  color: red;
  font-weight: 500;
}

.album-modal {
  font-style: italic;
  font-size: 24px;
}

.info-modal {
  font-size: 18px;
}

ul {
  list-style-type: none;
  text-align: left;
  text-indent: -25px;
  margin-left: 25px;
}

.nav-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}


.black-nav {
  color: black;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 500;
  font-style: bold;
  
}





.black-nav-admin {
  color: black;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 500;
  font-style: bold;
  align-items: left;
}

.artist-display {
  position: relative;
  align-items: left;
  max-width: 980px;
}

.artist-display-text {
  font-family: 'Asset', Times, serif;
  position: absolute;
  bottom: -4px;
  left: -5px;
  font-size: calc(30px + (80 - 28) * ((100vw - 300px) / (1600 - 300)));
  color: white;
  line-height: 75%;
}

.artist-md-display-text {
  font-family: 'Asset', Times, serif;
  position: absolute;
  bottom: -4px;
  left: -4px;
  font-size: calc(20px + (80 - 20) * ((100vw - 300px) / (1600 - 300)));
  color: white;
  line-height: 75%;
}

.artist-sm-display-text {
  font-family: 'Asset', Times, serif;
  position: absolute;
  bottom: -4px;
  left: -4px;
  font-size: calc(16px + (52 - 20) * ((100vw - 300px) / (1600 - 300)));
  color: white;
  line-height: 75%;
}

.artist-display-tetema {
  font-family: 'Asset', Arial Black;
  position: absolute;
  bottom: -4px;
  left: 7px;
  font-size: calc(26px + (100 - 26) * ((100vw - 300px) / (1600 - 300)));
  color: white;
  line-height: 75%;
  font-weight: 900;
  font-style: bold;
}

.focus-text {
  font-size: 22px;
  font-style: bold;
  font-weight: 700;
}

.fake-mackie {
  font-family: 'Asset', Georgia, serif;
  font-size: 30px;
  color: rgb(0, 157, 224);
  color: var(--color-primary);
  text-transform: lowercase;
  text-align: left;
}

.fake-mackie-two {
  font-family: 'Asset', Georgia, serif;
  font-size: 30px;
  color: rgb(0, 157, 224);
  color: var(--color-primary);
  margin-left: 15px;
  text-transform: lowercase;
  text-align: left;
}

.fake-mackie-three {
  font-family: 'Asset', Georgia, serif;
  font-size: 20px;
  color: rgb(0, 157, 224);
  color: var(--color-primary);
  text-transform: lowercase;
  text-align: right;
  margin-right: 15px;
  opacity: 0.5;
}

.footer {
  font-size: 12px;
  opacity: 0.5;
  margin-left: 25px;
  text-align: left;
}

.pointer {
  cursor: pointer;
}

.admin-input {
  width: 70%;
}

.admin-input-small {
  width: 25%;
}

.btns {
  background-color: gray;
  font-size: 16px;
  border-radius: 14px;
  color: white;
  padding: 5px 24px;
  border: none;
}

.btns:hover {
  background-color: rgb(0, 157, 224);
  background-color: var(--color-primary);
  color: white;
}

.artist-link {
  color: black;
}

.artist-link:hover {
  color: rgb(0, 157, 224);
  color: var(--color-primary);
  text-decoration: none;
}

.crop {
  width: 180px;
}

.album-cover {
  height: 180px;
  width: 180px;
  object-fit: cover;
  transition-property: transform;
  transition-duration: 300ms;
}

.album-cover:hover {
  transform: scale(1.05);
}

@media all and (max-width: 800px) {
  .black-nav {
    font-size: 95%;
    margin-left: -7px;
    margin-right: -7px
  }
  .nav-box {
    flex-direction: column;
    justify-content: center;
  }
  .fake-mackie {
    font-size: 22px;
    margin-left: 15px
  }

  .fake-mackie-two{
    font-size: 22px;
  }

  .videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


}

